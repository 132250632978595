import React, { Fragment, useState } from "react";
import { WaitlistPopup } from "../ui/WaitlistPopup";
import { Dialog, Transition } from "@headlessui/react";

export const Hero = () => {
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-12 px-4 md:px-0">
      <div className="flex flex-col items-center justify-center px-6 md:p-0">
        <div className="text-center text-5xl text-gray-800 md:text-[80px] font-medium mt-4 uppercase">
          Find the best place
        </div>
        <div className="text-gray-800 text-4xl font-medium uppercase mt-4">
          to explore
        </div>
        <h1 className="text-center mt-4 font-semibold uppercase text-transparent text-7xl bg-clip-text bg-gradient-to-b from-yellow-500 to-gray-700">
          in the Bahamas
        </h1>
        <div className="text-center font-light text-gray-800 text-xl leading-[30px] mt-3">
          Changing the way people explore business by providing interactive
          solution.
        </div>
        <div className="flex items-center gap-4 mt-8">
          <button
            type="button"
            onClick={() => setIsOpen(true)}
            className="whitespace-nowrap text-gray-800 text-center  text-md font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner "
          >
            Watch Video
          </button>
          <button className="hidden md:block whitespace-nowrap text-gray-800 px-6 py-[10px] bg-zinc-300 rounded-[10px] text-md font-medium">
            Download the app
          </button>
        </div>
        <button
          type="button"
          onClick={() => setModal(true)}
          className="flex md:hidden text-md mt-4 md:mt-8 font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner "
        >
          Join waiting list
        </button>

        <div className="md:h-[40rem] md:w-[55rem] mt-8 md:mt-3">
          <img
            alt="hero"
            src="/images/iPhone15Pro.png"
            className="object-contain h-full w-full mx-auto"
          />
        </div>
        {/* <div className="text-xl -mt-0 md:-mt-10 font-light text-center">
          More than 10k+ companies have used our platform
        </div> */}
      </div>
      <WaitlistPopup isOpen={modal} closeModal={setModal} />

      {/*  Brands */}
      {/* <div className="flex flex-wrap items-center justify-between gap-6 px-2 mt-12 whitespace-nowrap ">
        <img alt="" src="/images/onetree.png" />
        <img alt="" src="/images/Figma.png" />
        <img alt="" src="/images/Google.png" />
        <img alt="" src="/images/Stripe.png" />
        <img alt="" src="/images/loreal.png" />
      </div> */}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex  w-full min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="embed-container">
                  <iframe
                    src="https://player.vimeo.com/video/972803298?autoplay=1&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    title="Archipelago Promotional video"
                    webkitAllowFullScreen 
                    mozallowfullscreen 
                    allowFullScreen
                  ></iframe>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
