import React from "react";

export const Island = () => {
  return (
    <div className="py-10 px-4 md:px-0">
      <div className="max-w-7xl mx-auto">
        <p className="text-gray-400 font-light text-[26px] ">Island</p>
        <div className=" text-gray-800 text-4xl font-semibold">
          Islands of the Bahamas: <br />A Tropical Paradise
        </div>
      </div>
      {/* <div className="max-w-7xl overflow-clip "> */}

      <div className="max-w--full overflow-hidden">
        <div style={{ "--speed": "20000ms" }} className="slider   gap-8 mt-8">
          <div className="flex-shrink-0 h-full w-72">
            <img
              alt=""
              src="/images/providence.png"
              className="w-full h-full"
            />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">C</span>abbage
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/grand.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">A</span>tlantis
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/bimini.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/berry.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/androspi.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/crooked.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img
              alt=""
              src="/images/eleutheran.png"
              className="w-full h-full"
            />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/cat.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/long.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/inaguan.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img
              alt=""
              src="/images/mayaguanan.png"
              className="w-full h-full"
            />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/ragged.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/salvador.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/rum.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/abaco.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
          <div className="flex-shrink-0 h-full w-72">
            <img alt="" src="/images/exumasn.png" className="w-full h-full" />
            {/* <span className="absolute bottom-4 tracking-wide text-5xl -left-10 font-medium text-white">
              <span className="text-yellow-400">D</span>une
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};
