import React from "react";
import { Hero } from "../components/home/Hero";
import { Island } from "../components/home/Island";
import { Feature } from "../components/home/Feature";
import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";

const Home = () => {
  return (
    <div className="font-hove">
      <Header />
      <Hero />
      <Island />
      <Feature />
      
      {/*  More Section */}
      <div className=" overflow-hidden max-w-7xl mx-auto bg-opacity-10 px-8 bg-violet-500 rounded-[20px] flex flex-col items-center justify-center mb-16">
        <p className="text-center text-gray-400 font-light text-[26px] mt-14">
          More
        </p>
        <p className="text-center text-black text-[40px] font-medium">
          Few more to know
        </p>
        <div className="max-w-lg text-center text-gray-500 text-sm leading-[21px]">
        Unlock the full potential of the Bahamas with Archipelago. Whether you're exploring new places or managing your business, our app offers seamless navigation and valuable insights for an exceptional island experience. Join us and make the most of island life with ease and efficiency.
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4 mt-8">
          <button className="w-48 text-gray-800 text-md font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner ">
            Get Started
          </button>
          <button className="w-48 text-gray-800 px-6 py-[10px] bg-zinc-300 rounded-[10px] text-md font-medium">
            Download the app
          </button>
        </div>
        <div className="flex items-center justify-between mt-20">
          <div className="">
            <img
              src="/images/allimages.png"
              alt=""
              className="h-full w-full object-contain"
            />
          </div>
          
        </div>
      </div>
      <div className="p-8 md:p-20 max-w-7xl mx-auto bg-opacity-30  bg-gradient-to-r from-yellow-100 from-10% via-yellow-200 via-40% to-yellow-50  rounded-lg">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <p className="text-zinc-800 text-3xl md:text-[35px] font-semibold">
            Begin an exclusive journey to help make The Bahamas a better <br />{" "}
            place.
          </p>
          <div className="flex flex-col md:flex-row items-center gap-4 mt-8">
            <button className="w-48 text-gray-800 text-md font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner ">
              Get Started
            </button>
            <button className="w-48 text-gray-800 px-6 py-[10px] bg-zinc-300 rounded-[10px] text-md font-medium">
              Download the app
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home