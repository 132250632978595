import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function DropdownMenu() {
  return (
    <div className=" md:hidden ">
      <Menu as="div" className="">
        <div className="flex">
          <Menu.Button className="-mt-2 w-full justify-center rounded-md   text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>

          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute  left-5  w-44 origin-top-right divide-y rounded-md bg-white drop-shadow-md ring-1 ring-black/5 focus:outline-none">
            <div className="p-4">
              <Menu.Item as="div" className="p-1">
                <Link to="/">Home</Link>
              </Menu.Item>
              <Menu.Item as="div" className="p-1">
                <Link to="/contact">Contact</Link>
              </Menu.Item>
              <Menu.Item as="div" className="p-1">
                <Link to="/terms">Terms & Privacy</Link>
              </Menu.Item>
              <Menu.Item as="div" className="p-1">
                <Link to="/privacy">Privacy Policy</Link>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
