import React from "react";

export const Feature = () => {
  return (
    <div className="max-w-7xl mx-auto py-10 px-4 md:px-0">
      {/* First Section */}
      <p className="text-gray-400 font-light text-[26px]">Feature</p>
      <div className=" text-gray-800 text-4xl font-semibold">
        Experience Our App's Unique <br className="hidden md:block" />{" "}
        Capabilities
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between gap-16 mb-24">
        <div className="flex-1 px-10 md:px-20 pt-8 bg-opacity-10 bg-yellow-400 rounded-[20px] mt-10">
          <div className="h-full w-72 mx-auto">
            <img
              alt=""
              src="/images/feature1.png"
              className="mx-auto h-full object-cover w-full"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-8">
          <div className="px-4 border-l-4 border-gray-200">
            <p className=" text-gray-500 text-2xl font-medium">
              Search categories
            </p>
            <div className=" text-gray-500 text-sm leading-6">
              Quickly find what you're looking for by exploring our organized
              categories, from beaches to restaurants, ensuring an easy and
              enjoyable island experience.
            </div>
          </div>
          <div className="px-4 border-l-4 border-gray-400">
            <p className=" text-gray-800 text-2xl font-medium">
              Search by Island
            </p>
            <div className=" text-gray-700 text-sm leading-6">
              Easily discover places by selecting your desired island, allowing
              you to find attractions and services specific to each unique
              Bahamian island.
            </div>
          </div>

          <div className="flex items-center gap-4 mt-8">
            <button className="text-gray-800 text-center  text-md font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner ">
              Get Started
            </button>
            <button className="text-gray-800 px-6 py-[10px] bg-zinc-300 rounded-[10px] text-md font-medium">
              Download the app
            </button>
          </div>
        </div>
      </div>

      {/* Third Section */}
      <p className="text-gray-400 font-light text-[26px]">Feature</p>
      <div className=" text-gray-800 text-4xl font-semibold">
        Explore Business Highlights <br /> on the Island Feed
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between gap-16 mb-24">
        <div className="flex-1 px-10 md:px-20 pt-4 bg-opacity-10  bg-yellow-400 rounded-[20px] mt-10">
          <div className="h-full w-80 mx-auto">
            <img
              alt=""
              src="/images/Frame4.png"
              className="mx-auto h-full object-cover w-full"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-8">
          <div className="px-4 border-l-4 border-gray-400">
            <p className=" text-gray-700 text-2xl font-medium">
            Discover and Engage with Local Business Highlights
            </p>
            <div className=" text-sm leading-6">
              <ul className="list-disc mt-2 ml-4">
                <li>
                  Watch engaging reels posted by registered businesses in the Bahamas.
                </li>
                <li>Like and share your favorite business posts.</li>
                <li>
                  Get updates on the latest promotions, events, and offerings from businesses.
                </li>
                <li>
                  Explore and discover new places and services through captivating visuals.
                </li>
                <li>
                  Enjoy a feed tailored to your interests, showcasing relevant business reels.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

       {/* Second Section */}
       <p className="text-gray-400 font-light text-[26px]">Feature</p>
      <div className=" text-gray-800 text-4xl font-semibold">
        What you can get in Island <br /> with Archipelago
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between gap-16 mb-24">
        <div className="flex-1 px-10 md:px-20 pt-4 bg-opacity-10  bg-yellow-400 rounded-[20px] mt-10">
          <div className="h-full w-80 mx-auto">
            <img
              alt=""
              src="/images/Frame6.png"
              className="mx-auto h-full object-cover w-full"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-8">
          <div className="px-4 border-l-4 border-gray-400">
            <p className=" text-gray-700 text-2xl font-medium">
              Manage your business with ease
            </p>
            <div className=" text-sm leading-6">
              <p className="mt-2">
                The Archipelago app empowers business owners with robust tools
                to enhance their visibility and operations.{" "}
              </p>
              <ul className="list-disc  mt-2 ml-4">
                <li>
                  Create and customize your business profile for better
                  visibility.
                </li>
                <li>
                  Add addresses and categorize services for easy discovery by
                  users.
                </li>
                <li>
                  Monitor visitor stats and page views to gain insights into
                  customer engagement.
                </li>
                <li>
                  Seamlessly manage your listings, ensuring accurate and
                  appealing information.
                </li>
                <li>
                  Post engaging reels to attract more visitors and showcase your
                  offerings.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col md:flex-row items-center justify-between gap-28 mt-10">
        <div className="flex-1 px-20 pt-8 bg-opacity-10 bg-yellow-400 rounded-[20px]">
          <img
            alt=""
            src="/images/islandiphone.png"
            className="mx-auto h-full w-full"
          />
        </div>
        <div className="flex-1 flex flex-col gap-8">
          <div className="px-4 border-l-4 border-gray-400">
            <p className=" text-gray-800 text-2xl font-medium">
              Manage your business with ease
            </p>
            <div className=" text-gray-700 text-sm leading-6">
              The Archipelago app empowers business owners with robust tools to
              enhance their visibility and operations. Create and customize your
              business profile for better visibility. Add addresses and
              categorize services for easy discovery by users. Monitor visitor
              stats and page views to gain insights into customer engagement.
              Seamlessly manage your listings, ensuring accurate and appealing
              information. Post engaging reels to attract more visitors and
              showcase your offerings.
            </div>
          </div>
        </div> */}

      {/* <div className="flex-1">
          <MyDisclosure
            active={true}
            title="Grand Bahama"
            content="Lorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristiqueLorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristique"
          />
          <MyDisclosure
            active={false}
            title="New Provicne"
            content="Lorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristiqueLorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristique"
          />
          <MyDisclosure
            active={false}
            title="Acklinks Island"
            content="Lorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristiqueLorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristique"
          />
          <MyDisclosure
            active={false}
            title="Andros Island"
            content="Lorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristiqueLorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristique"
          />
          <MyDisclosure
            active={false}
            title="The Exumas Island"
            content="Lorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristiqueLorem ipsum dolor sit amet consectetur. Ipsum ac ornare
              tristique"
          />
        </div> */}
      {/* </div> */}

      {/* Third Section */}
      <div className="mt-10 overflow-hidden p-3 bg-opacity-10 bg-violet-500 rounded-[20px] ">
        <div className="bg-white w-full rounded-[20px] flex flex-col md:flex-row items-center justify-between p-8 md:p-16">
          <div className="flex-1 w-full md:w-96">
            <div className=" text-zinc-800 text-2xl md:text-4xl font-semibold leading-relaxed">
              Visitor Insights and Analytics
            </div>
            <div className="w-full text-gray-600 text-sm leading-[21px]">
              Track visitor stats and page views to understand customer
              engagement and behavior. Use these insights to optimize your
              listings and enhance your business strategy, ensuring effective
              growth on the islands of the Bahamas.
            </div>
          </div>
          <div className="flex-1 w-full h-60">
            <img alt="" src="/images/chartsvg.svg" className="w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};
