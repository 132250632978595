import React from "react";
import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";

const Terms = () => {
  return (
    <div className="font-hove">
      <Header />
      <div className="max-w-7xl mx-auto flex flex-col items-center justify-center mt-12">
        <div className="text-center text-gray-800 text-4xl md:text-[80px] font-medium  uppercase">
          Terms of service{" "}
        </div>
        <div className="font-light text-gray-800 text-xl leading-[30px] mt-2 md:mt-8">
          Last update {new Date().toDateString()}
        </div>
        <div className="h-80 md:h-[670px] mt-8">
          <img alt="" src="/images/woodenhand2.png" className="h-full w-full object-contain" />
        </div>
        <div className="w-full bg-violet-50 rounded-[20px] p-8 md:p-12 -mt-32 md:-mt-72">
          <div className="flex flex-col-reverse md:flex-row justify-between">
            <div>
              <p className="text-gray-800 text-[32px] font-semibold">
                Terms & condition
              </p>
              <p className=" text-gray-600 text-lg ">
                Our main motive is care the privacy of our unique client
              </p>
            </div>
            {/* <div className="relative mb-3 md:mb-0">
              <input
                className="rounded-lg pl-8 pr-3 py-2 text-zinc-800 w-full md:w-96 border border-zinc-300 bg-transparent focus:outline-none "
                placeholder="Search keywords"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-search absolute left-2 top-3 h-5 w-5"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#9e9e9e"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                <path d="M21 21l-6 -6" />
              </svg>
            </div> */}
          </div>

          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            1. Acceptance of Terms
          </p>
          <p className="text-gray-600">
            By accessing or using the Archipelago app, you acknowledge that you
            have read, understood, and agree to be bound by these Terms of
            Service and our Privacy Policy. If you do not agree, please do not
            use the app.
          </p>
          {/* <br />
          <p className="text-gray-600">
            Lorem ipsum dolor sit amet consectetur. Sit quam tincidunt vulputate
            pretium egestas nisi accumsan integer. Congue vitae sed tristique
            justo eget nec egestas. Interdum hac egestas vivamus laoreet egestas
            pellentesque felis quis arcu
          </p> */}

          {/* Terms of Services */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            2. Description of Service
          </p>
          <p className="text-gray-600">
            Archipelago is a platform designed to help users discover places and
            businesses on the Bahamian islands. It provides features such as
            account creation, searching and filtering for places, detailed
            business information, ratings, contact details, and directions.
            Business owners can create profiles, manage listings, post reels,
            and view visitor analytics.
          </p>
          {/* <br />
          <p className="text-gray-600">
            Lorem ipsum dolor sit amet consectetur. Sit quam tincidunt vulputate
            pretium egestas nisi accumsan integer. Congue vitae sed tristique
            justo eget nec egestas. Interdum hac egestas vivamus laoreet egestas
            pellentesque felis quis arcu
          </p> */}

          {/*  Policy  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            3. User Accounts
          </p>
          <div className="text-gray-600">
            <ul className="list-disc list-inside">
              <li>
                <span className="font-medium">Registration:</span> Users must
                provide accurate and complete information when creating an
                account. You are responsible for maintaining the confidentiality
                of your login credentials and all activities that occur under
                your account.
              </li>
              <li>
                <span className="font-medium">Account Security:</span> You must
                immediately notify us of any unauthorized use of your account.
                We are not liable for any loss or damage arising from your
                failure to comply with this obligation.
              </li>
              <li>
                <span className="font-medium">Termination:</span> We reserve the
                right to suspend or terminate your account if you violate these
                terms, provide false information, or engage in any activity that
                is harmful to the app or other users.
              </li>
            </ul>
          </div>

          {/* Terms of Use */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            4. Business Accounts
          </p>
          <div className="text-gray-600">
            <ul className="list-disc list-inside">
              <li>
                <span className="font-medium">Profile </span>Creation: Business
                owners can create and manage their profiles, including adding
                addresses, categories, and promotional content. All information
                provided must be accurate, current, and complete.
              </li>
              <li>
                <span className="font-medium">Compliance:</span> Businesses must
                comply with all local, state, national, and international laws,
                rules, and regulations. We reserve the right to remove any
                content that violates these laws or our policies.
              </li>
              <li>
                <span className="font-medium">Analytics:</span> Business owners
                can access analytics to monitor visitor engagement and page
                views. This information helps businesses understand customer
                behavior and improve their offerings.
              </li>
            </ul>
          </div>

          {/*  Personal Data  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            5. User Conduct
          </p>
          <div className="text-gray-600">
            <ul className="list-disc list-inside">
              <li>
                <span className="font-medium">Prohibited Actions:</span> Users
                are prohibited from engaging in unlawful activities, uploading
                harmful or offensive content, infringing on the rights of
                others, or disrupting the operation of the app.
              </li>
              <li>
                <span className="font-medium">Community Standards:</span> Users
                must respect the community standards and the rights of other
                users and businesses. Any violation of these standards may
                result in the suspension or termination of your account.
              </li>
            </ul>
          </div>

          {/*  Encrypted Data  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            6. Content Ownership and Usage
          </p>
          <div className="text-gray-600">
            <ul>
              <li>
                <span className="font-medium">User Content: </span> Users retain
                ownership of the content they upload but grant Archipelago a
                worldwide, non-exclusive, royalty-free license to use, display,
                reproduce, and distribute such content on the app.
              </li>
              <li>
                <span className="font-medium">Intellectual Property: </span> All
                intellectual property rights in the app and its content are
                owned by Archipelago or its licensors. Users are granted a
                limited, non-exclusive, non-transferable license to access and
                use the app for personal and business purposes.
              </li>
            </ul>
          </div>

          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            7. Privacy Policy
          </p>
          <div className="text-gray-600">
            Our Privacy Policy outlines how we collect, use, and protect your
            personal information. By using the app, you consent to our data
            practices as described in the Privacy Policy.
          </div>

          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            8. Disclaimers
          </p>
          <div className="text-gray-600">
            <ul className="list-disc list-inside">
              <li>
                <span className="font-medium"> Service Availability:</span> The
                app is provided "as is" and "as available." We do not guarantee
                that the app will be uninterrupted, error-free, or free from
                viruses or other harmful components.
              </li>
              <li>
                <span className="font-medium"> No Warranties: </span> We
                disclaim all warranties, express or implied, including the
                warranties of merchantability, fitness for a particular purpose,
                and non-infringement.
              </li>
            </ul>
          </div>
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            9. Limitation of Liability
          </p>
          <div className="text-gray-600">
            In no event shall Archipelago be liable for any indirect,
            incidental, special, consequential, or punitive damages, including
            but not limited to loss of profits, data, use, goodwill, or other
            intangible losses, resulting from (i) your access to or use of or
            inability to access or use the app; (ii) any conduct or content of
            any third party on the app; (iii) any content obtained from the app;
            and (iv) unauthorized access, use, or alteration of your
            transmissions or content.
          </div>

          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            10. Indemnification
          </p>
          <div className="text-gray-600">
            You agree to defend, indemnify, and hold harmless Archipelago, its
            affiliates, licensors, and service providers, and their respective
            officers, directors, employees.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
