import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export const WaitlistPopup = ({ isOpen, closeModal }) => {
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    businessName: "",
  });
  const [state, setState] = useState({
    name: "",
    email: "",
    businessName: "",
  });
  const pattern = /^[^\d]*$/;

  function validateInput(input) {
    return pattern.test(input);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    if(name === "email") {
      setErrors({
        ...errors,
        email: ""
      })
    }
    if(name === "name") {
      setErrors({
        ...errors,
        name: ""
      })
    }
    setState({
      ...state,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(!validateInput(state.name)) {
      setErrors({
        ...errors,
        name: "Name should only contain alphabetic characters",
      })
      return
    }

    if(!state.name ) {
      setErrors({
        ...errors,
        name: "Please enter name",
      })

      return
    }

    // if(!state.name && !state.email) {
    //   setErrors({
    //     ...errors,
    //     name: "Please enter name",
    //     email: "Please enter email"
    //   })

    //   return
    // }

    if(!state.email) {
      setErrors({
        ...errors,
        email: "Please enter email"
      })

      return
    }

    if(!state.businessName) {
      console.log("herhehehr")
      setErrors({
        ...errors,
        businessName: "Please enter business name"
      })

      return
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    };
    fetch(
      "https://apis.archipelagosolutions.net/waitinglist/join-waiting-list",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        alert("You have joined the waiting list");
        closeModal(false);
      });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {
        closeModal(false)
        setErrors({
          name: "",
          email: "",
          businessName: ""
        })
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full md:max-w-4xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col-reverse md:flex-row gap-4">
                  <div className="h-full w-full md:w-80">
                    <img
                      src="/Frame 2.png"
                      className="h-full object-cover w-full"
                      alt="Logo"
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-2">
                    <h1 className="font-semibold text-3xl">
                      Be the First to Experience Archipelago
                    </h1>
                    <p className="text-gray-600 font-light">
                      Sign up for our waitlist and get early access to all the
                      features of the Archipelago app. Be among the first to
                      discover and explore the best places on the Bahamian
                      islands, or manage and promote your business with ease.
                      Don’t miss out on the opportunity to enhance your island
                      experience!
                    </p>
                    <form
                      onSubmit={handleSubmit}
                      className="mt-4 flex flex-col gap-3"
                    >
                      <div className="flex gap-2">
                        <div className="relative flex-1">
                          <input
                            type="text"
                            id="fullName"
                            className={`block shadow-sm px-2.5 py-3 w-full text-sm ${errors?.name ? "border-red-400" : "border-gray-400"} bg-transparent rounded-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0  peer`}
                            placeholder=" "
                            name="name"
                            onChange={handleChange}
                            autoComplete="nope"
                            autoCorrect="off"
                          />

                          <label
                            htmlFor="fullName"
                            className={`absolute cursor-pointer text-xs font-light  text-gray-600  -top-1.5  z-10 ]  bg-white px-1 left-2 `}
                          >
                            Full Name
                          </label>
                          <p className=" text-sm text-red-500 mt-0.5 ml-0.5">{errors.name}</p>

                        </div>

                        <div className={`relative flex-1`}>
                          <input
                            type="text"
                            id="businessName"
                            className={`block shadow-sm px-2.5 py-3 w-full text-sm  bg-transparent rounded-lg border ${errors?.businessName ? "border-red-400" : "border-gray-400"}  appearance-none focus:outline-none focus:ring-0  peer`}
                            placeholder=" "
                            name="businessName"
                            onChange={handleChange}
                            autoComplete="nope"
                            autoCorrect="off"
                          />
                          <label
                            htmlFor="businessName"
                            className={`absolute cursor-pointer text-xs font-light  text-gray-600  -top-1.5  z-10 ]  bg-white px-1 left-2 `}
                            // className={`absolute cursor-pointer text-sm font-light duration-300 text-gray-600 transform -translate-y-4 scale-75 peer-focus:text-sm top-2 z-10 origin-[0]  bg-white px-3 peer-focus:px-3 peer-focus:text-gray-500 peer-placeholder-shown:scale-100 ${errors.name ? "peer-placeholder-shown:-translate-y-5" : "peer-placeholder-shown:-translate-y-1/2"} peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                          >
                            Business Name
                          </label>
                          <p className=" text-sm text-red-500 mt-0.5 ml-0.5">{errors.businessName}</p>

                        </div>
                      </div>
                      <div className="relative flex-1">
                        <input
                          type="email"
                          id="email"
                          className={`block shadow-sm px-2.5 py-3 w-full text-sm  bg-transparent rounded-lg border ${errors?.email ? "border-red-400" : "border-gray-400"} appearance-none focus:outline-none focus:ring-0  peer`}
                          placeholder=" "
                          name="email"
                          onChange={handleChange}
                          autoComplete="nope"
                          autoCorrect="off"
                        />
                        <label
                          htmlFor="email"
                          className={`absolute cursor-pointer text-xs font-light  text-gray-600  -top-1.5  z-10 ]  bg-white px-1 left-2 `}
                          // className="absolute cursor-pointer text-sm font-light duration-300 text-gray-600 transform -translate-y-4 scale-75 peer-focus:text-sm top-2 z-10 origin-[0]  bg-white px-3 peer-focus:px-3 peer-focus:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-5 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Email address
                        </label>
                        <p className=" text-sm text-red-500 mt-0.5 ml-0.5">{errors.email}</p>

                      </div>
                      <button
                        type="submit"
                        className="text-md font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner "
                      >
                        Join waitlist
                      </button>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
