import { Link } from "react-router-dom";
import DropdownMenu from "../ui/DropdownMenu";
import { WaitlistPopup } from "../ui/WaitlistPopup";
import { useState } from "react";

export const Header = () => {
  const [modal, setModal] = useState(false)
  return (
    <div className="flex flex-row-reverse md:flex-row px-4 md:px-12 py-6 items-center justify-between">
      {/* LOGO */}
      <div className="-mt-2">
        <img alt="" src="/images/logo.svg" />
      </div>
      {/* MENU */}
      <div className="hidden md:flex items-center gap-12 font-medium tracking-wide text-gray-800">
        <Link to="/">Home</Link>
        <Link to="/contact">Contact Us</Link>
        <Link to="/terms">Terms & Privacy</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </div>

      {/* BUTTTONS */}
      <div className="hidden md:flex items-center gap-4 text-gray-800">
        {/* <button className="font-medium">Signin</button> */}
        <button type="button" onClick={()=> setModal(true)} className="text-md font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner ">
          Join waiting list
        </button>
      </div>
      <DropdownMenu />
      <WaitlistPopup isOpen={modal} closeModal={setModal} />
    </div>
  );
};
