import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="border-t border-zinc-200 mx-auto p-5 mt-8">
       {/* <div className="max-w-7xl mx-auto flex items-center gap-8 mt-10 ">
        <div className="bg-gray-100 h-[2px] flex-1 " />
        <img alt="" src="/fav.png" className="h-8 w-8 object-contain" />
        <div className="bg-gray-100 h-[2px] flex-1" />
      </div>
      <div className="flex flex-col md:flex-row gap-5 items-center justify-between">
        <div className="hidden md:flex items-center gap-8 text-gray-500">
          <Link href="/terms">Terms of Use</Link>
          <Link href="/privacy">Privacy Policy</Link>
          <Link href="/contact">Contact</Link>
          <Link href="/">Blog</Link>
        </div>
        <button className="text-gray-800 text-center text-md font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner mt-10">
          Get the app
        </button>
        <div className="flex items-center gap-8 text-gray-500">
          <Link href="/">Pricing</Link>
          <Link href="/">Features</Link>
          <Link href="/">Integrations</Link>
          <Link href="/">Company</Link>
        </div>
      </div> */}
      {/* <div className="text-center text-gray-500 mt-8">Copyright © 2023</div> */}

      <div className="max-w-7xl flex justify-between items-center mx-auto">
      <div className="">
        <img alt="" src="/images/logo.svg" />
      </div>
      <div className="text-center text-gray-400 ">© 2024 Archipelago. All rights reserved.</div>

      </div>
    </footer>
  )
}
