import React from "react";
import { Header } from "../components/layout/Header";
import { Footer } from "../components/layout/Footer";

const Privacy = () => {
  return (
    <div className="font-hove">
      <Header />
      <div className="max-w-7xl mx-auto flex flex-col items-center justify-center mt-12">
        <div className="text-center text-gray-800 text-4xl md:text-[80px] font-medium  uppercase">
          Privacy Policy
        </div>
        <div className="text-center font-light text-gray-800 text-xl leading-[30px] mt-3 md:mt-8">
          Last update {new Date().toDateString()}
        </div>
        <div className="h-80 md:h-[670px] mt-8">
          <img src="/images/woodenhand3.png" alt="" className="h-full w-full object-contain" />
        </div>
        <div className="w-full bg-violet-50 rounded-[20px] p-8 md:p-12 -mt-32 md:-mt-72">
          <div className="flex flex-col-reverse md:flex-row justify-between">
            <div>
              <p className="text-gray-800 text-[32px] font-semibold">
                Privacy Policies
              </p>
              <p className=" text-gray-600 text-lg ">
                Our main motive is care the privacy of our unique client
              </p>
            </div>
            {/* <div className="relative mb-3 md:mb-0">
              <input
                className="rounded-lg pl-8 pr-3 py-2 text-zinc-800 w-full md:w-96 border border-zinc-300 bg-transparent focus:outline-none "
                placeholder="Search keywords"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-search absolute left-2 top-3 h-5 w-5"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#9e9e9e"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                <path d="M21 21l-6 -6" />
              </svg>
            </div> */}
          </div>
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            1. Introduction
          </p>
          <p className="text-gray-600">
            Archipelago ("we," "us," or "our") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you use our mobile application
            (the "App").
          </p>

          {/* Terms of Services */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            2. Information We Collect{" "}
          </p>
          <p className="text-gray-600">
            <ul className="list-disc list-inside">
              <li>
                <span className="font-medium">Personal Data:</span> When you
                register on our app, we may collect personal information such as
                your name, email address, phone number, and payment information.
              </li>
              <li>
                <span className="font-medium">Usage Data:</span> We may collect
                information about your interactions with the app, including the
                features you use, the pages you visit, and the actions you take.
              </li>
              <li>
                <span className="font-medium">Device Information:</span> We may
                collect information about the device you use to access the app,
                including the hardware model, operating system and version,
                unique device identifiers, and mobile network information.
              </li>
            </ul>
          </p>

          {/*  Policy  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            3. Use of Your Information
          </p>
          <p className="text-gray-600">
            We use the collected information to:
            <ul className="list-disc list-inside ml-4">
              <li>Provide, operate, and maintain the app.</li>
              <li>Improve, personalize, and expand our services.</li>
              <li>Understand and analyze how you use our app.</li>
              <li>
                Develop new products, services, features, and functionality.
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the app, and for
                marketing and promotional purposes.
              </li>
              <li>
                Process your transactions and send you related information.
              </li>
              <li>Find and prevent fraud.</li>
            </ul>
          </p>

          {/* Terms of Use */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            4. Disclosure of Your Information
          </p>
          <div className="text-gray-600">
            We may share your information with:
            <li>
              <span>Service Providers:</span> We may share your information with
              third-party service providers who perform services on our behalf.
            </li>
            <li>
              <span>Business Transfers:</span> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <span>Legal Requirements:</span> We may disclose your information
              where required to do so by law or in response to valid requests by
              public authorities.
            </li>
          </div>

          {/*  Personal Data  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            5. Security of Your Information
          </p>
          <p className="text-gray-600">
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse.
          </p>

          {/*  Encrypted Data  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            6. Your Data Protection Rights
          </p>
          <div className="text-gray-600">
            Depending on your location, you may have the following rights
            regarding your personal information:
            <ul className="list-disc list-inside ml-4">
              <li>
                The right to access – You have the right to request copies of
                your personal data.
              </li>
              <li>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate or complete
                information you believe is incomplete.
              </li>
              <li>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </li>
              <li>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </li>
              <li>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
            </ul>
          </div>

          {/*  Encrypted Data  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            7. Children's Privacy
          </p>
          <div className="text-gray-600">
            Our service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13. If we become aware that we have collected personal data
            from a child under age 13 without verification of parental consent,
            we take steps to remove that information from our servers.
          </div>

          {/*  Encrypted Data  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            8. Changes to This Privacy Policy
          </p>
          <div className="text-gray-600">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.{" "}
          </div>

          {/*  Encrypted Data  */}
          <p className="text-violet-500 text-[26px] font-semibold  leading-[43px] mt-4">
            9. Contact Us{" "}
          </p>
          <div className="text-gray-600">
            If you have any questions about this Privacy Policy, please contact
            us at [archipelago2023@hotmail.com].
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
