import React from "react";
import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="font-hove">
      <Header />
      <div className="flex flex-col items-center justify-center mt-12">
        <div className="text-gray-800 text-4xl md:text-[80px] font-medium  uppercase">
          Contact us
        </div>
        <div className="text-center font-light text-gray-800 text-xl leading-[30px] mt-3 md:mt-8">
          Changing the way people explore business by providing interactive
          solution.
        </div>
        <div className="h-80 md:h-[680px] mt-8">
          <img alt="" src="/images/woodenhand1.png" className="h-full w-full object-contain" />
        </div>

        <div className="-mt-32 md:-mt-96 max-w-7xl w-full  bg-violet-500 rounded-[20px] shadow-inner p-10 flex  flex-col-reverse md:flex-row gap-8">
          <div className=" bg-yellow-100 rounded-[20px] p-10 w-full md:w-96">
            <div className="text-zinc-800 text-[28px] font-medium leading-[43px]">
              Get in touch
            </div>
            <div className="mt-6">
              <div className="text-violet-500 text-lg font-medium leading-[30px] ">
                Visit us
              </div>
              <div className=" text-gray-700 font-light  leading-[23px]">
                Come say hello at our office
              </div>
              <div className="text-zinc-800 font-medium leading-[23px]">
                New Providence, The Bahamas
              </div>
            </div>
            <div className="mt-6">
              <div className="text-violet-500 text-lg font-medium leading-[30px] ">
                Chat with us
              </div>
              <div className=" text-gray-700 font-light  leading-[23px]">
                Our friendly team is here to help{" "}
              </div>
              <div className="text-zinc-800 font-medium leading-[23px]">
              archipelago2023@hotmail.com{" "}
              </div>
            </div>
            <div className="mt-6">
              <div className="text-violet-500 text-lg font-medium leading-[30px] ">
                Call us
              </div>
              <div className=" text-gray-700 font-light  leading-[23px]">
                Mon to Sat from 10am to 6pm
              </div>
              <div className="text-zinc-800 font-medium leading-[23px]">
              2425339451 or 7542557357
              </div>
            </div>
            <div className="mt-6">
              <div className="text-violet-500 text-lg font-medium leading-[30px] ">
                Social Media
              </div>

              <div className="text-zinc-800 flex gap-2 font-medium leading-[23px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-youtube-filled h-6 w-6"
                  width="100"
                  height="100"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#9e9e9e"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 3a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-12a5 5 0 0 1 -5 -5v-8a5 5 0 0 1 5 -5zm-9 6v6a1 1 0 0 0 1.514 .857l5 -3a1 1 0 0 0 0 -1.714l-5 -3a1 1 0 0 0 -1.514 .857z"
                    stroke-width="0"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-twitter-filled h-6 w-6"
                  width="100"
                  height="100"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#9e9e9e"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M14.058 3.41c-1.807 .767 -2.995 2.453 -3.056 4.38l-.002 .182l-.243 -.023c-2.392 -.269 -4.498 -1.512 -5.944 -3.531a1 1 0 0 0 -1.685 .092l-.097 .186l-.049 .099c-.719 1.485 -1.19 3.29 -1.017 5.203l.03 .273c.283 2.263 1.5 4.215 3.779 5.679l.173 .107l-.081 .043c-1.315 .663 -2.518 .952 -3.827 .9c-1.056 -.04 -1.446 1.372 -.518 1.878c3.598 1.961 7.461 2.566 10.792 1.6c4.06 -1.18 7.152 -4.223 8.335 -8.433l.127 -.495c.238 -.993 .372 -2.006 .401 -3.024l.003 -.332l.393 -.779l.44 -.862l.214 -.434l.118 -.247c.265 -.565 .456 -1.033 .574 -1.43l.014 -.056l.008 -.018c.22 -.593 -.166 -1.358 -.941 -1.358l-.122 .007a.997 .997 0 0 0 -.231 .057l-.086 .038a7.46 7.46 0 0 1 -.88 .36l-.356 .115l-.271 .08l-.772 .214c-1.336 -1.118 -3.144 -1.254 -5.012 -.554l-.211 .084z"
                    stroke-width="0"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-instagram-filled h-6 w-6"
                  width="100"
                  height="100"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#9e9e9e"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                  <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M16.5 7.5l0 .01" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full md:flex-1">
            <p className="text-white font-light text-3xl">
              Our friendly team will love to hear from you
            </p>
            <form className="mt-4 flex flex-col gap-5">
              <div className="flex items-center gap-4">
                <div className="relative flex-1">
                  <input
                    type="text"
                    id="firstName"
                    className="block px-2.5 pb-2.5 pt-3 w-full text-sm text-white bg-transparent rounded-lg border border-white appearance-none focus:outline-none focus:ring-0  peer"
                    placeholder=" "
                    autoComplete="nope"
                    autoCorrect="off"
                  />
                  <label
                    htmlFor="firstName"
                    className="absolute text-sm font-light text-white duration-300 transform -translate-y-4 scale-75 peer-focus:text-sm top-2 z-10 origin-[0]  bg-violet-500 px-3 peer-focus:px-3 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    First Name
                  </label>
                </div>
                <div className="relative flex-1">
                  <input
                    type="text"
                    id="lastName"
                    autoComplete="nope"
                    className="block px-2.5 pb-2.5 pt-3 w-full text-sm text-white bg-transparent rounded-lg border border-white appearance-none focus:outline-none focus:ring-0  peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor="lastName"
                    className="absolute text-sm font-light text-white duration-300 transform -translate-y-4 scale-75 peer-focus:text-sm top-2 z-10 origin-[0]  bg-violet-500 px-3 peer-focus:px-3 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Last Name
                  </label>
                </div>
              </div>
              <div className="relative">
                <input
                  type="text"
                  id="email"
                  autoComplete="nope"
                  className="block px-2.5 pb-2.5 pt-3 w-full text-sm text-white bg-transparent rounded-lg border border-white appearance-none focus:outline-none focus:ring-0  peer"
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  className="absolute text-sm font-light text-white duration-300 transform -translate-y-4 scale-75 peer-focus:text-sm top-2 z-10 origin-[0]  bg-violet-500 px-3 peer-focus:px-3 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Email address
                </label>
              </div>
              <div className="relative">
                <input
                  type="text"
                  id="contact"
                  autoComplete="nope"
                  className="block pl-8 pr-2.5 pb-2.5 pt-3 w-full text-sm text-white bg-transparent rounded-lg border border-white appearance-none focus:outline-none focus:ring-0  peer"
                  placeholder=" "
                />
                <label
                  htmlFor="contact"
                  className="absolute text-sm font-light text-white duration-300 transform -translate-y-4 scale-75 peer-focus:text-sm top-2 z-10 origin-[0]  bg-violet-500 pl-8 pr-3 peer-focus:px-3 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Contact number
                </label>
                <p
                  contentEditable
                  className="absolute left-3 top-[13px] font-light text-sm text-white z-10"
                >
                  +1
                </p>
              </div>
              <div className="relative">
                <textarea
                  id="messsage"
                  className="block px-2.5 pb-2.5 pt-3 w-full text-sm text-white bg-transparent rounded-lg border border-white appearance-none focus:outline-none focus:ring-0  peer"
                  placeholder=" "
                  rows={6}
                />
                <label
                  htmlFor="messsage"
                  autoFocus
                  className="absolute font-light text-white duration-300 transform -translate-y-2 scale-75 top-0  z-10   bg-violet-500 px-3 peer-focus:px-3 peer-focus:text-white start-1"
                >
                  Message
                </label>
              </div>

              <label className="text-zinc-300 ">
                <input type="checkbox" className="mr-1 h-4 w-4 mt-1.5" />
                I’d like to receive more information about company, I agree with the <Link to="/terms" className="text-white underline ">terms & condition</Link> and <Link to="/privacy" className="text-white underline ">privacy policies</Link> of the app.</label>

              <button className="w-full text-gray-800 text-center text-md font-medium px-6 py-[10px] bg-yellow-400 rounded-[10px] shadow-inner ">
                Send message
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
